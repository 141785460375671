/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.css');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.

// jQuery
window.$ = require("jquery");

// Select2
window.select2 = require('select2');
import 'select2/dist/css/select2.css';

window.moment = require("moment");
window.Chart = require("chart.js");


require("bootstrap");
require("../css/global.scss" );

import tinymce from 'tinymce/tinymce';
// import 'tinymce/themes/modern/theme';

import Vue from 'vue';
window.Vue = Vue;

require('tinymce');

// shrink Logo on scroll
$(window).scroll(function() {
    if( $(this).scrollTop() > 120) {
        $("#logo_big").addClass("small");
    }
    if( $(this).scrollTop() <60) {
        $("#logo_big").removeClass("small");
    }
});

// Swiper.js
import Swiper from 'swiper';
require("swiper/css/swiper.min.css");
window.Swiper = Swiper;

